input:-webkit-autofill {
    -webkit-text-fill-color: white !important;
    -webkit-box-shadow: 0 0 0px calc(var(--unit) * 2) var(--color-inputfield) inset !important; /* Change to your desired color */
    background-clip: content-box !important;
}

:root {
  --page-background-image-size: calc(var(--unit) * 1.5);;
  --page-background-image: url('assets/images/tile-diagonal.png');

  --color-primary: #fff;
  --color-accent-primary: #ffa500;
  --color-accent-secondary: #ffd000;

  --color-page-background: #222;
  --color-window: #333;
  --color-inputfield: #444;
  --color-disabled: #333;
  --color-disabled-content: #555;
  --color-lightgrey: #777;

  --color-level-window: rgba(0, 0, 0, 0);

  --logo-width: calc(var(--unit) * 16);
  --header-justify-content: center;

  --unit: 20px;
}

@media (max-width: 429px) {
    :root {
        --unit: 4.68vw;
    }
}

.App {
  height: 100%;
}


.login-form-container {
  display: flex;
  flex-direction: column;
  gap: calc(var(--unit) * 0.5);
  min-width: var(--logo-width);
  margin-bottom: calc(var(--unit) * 2);
  margin-top: calc(var(--unit) * 2);
}

.small-form {
  display: flex;
  flex-direction: column;
  gap: calc(var(--unit) * 0.5);
}

body {
  text-align: center;
  background-color: var(--color-page-background);
  background-image: var(--page-background-image);
  background-size: var(--page-background-image-size);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: var(--unit);
  color: white;
  position: relative;
  height: 100%;
}

a {
  color: var(--color-accent-primary);
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  color: var(--color-accent-secondary);
}


h1 span {
  text-wrap: nowrap;
}

h1 {
  margin-top: 0;
}

input, button {
  appearance: none;
  -webkit-appearance: none;
  border-radius: calc(var(--unit) * 2);
  font-size: var(--unit);
  display: flex;
  align-items: center;
  justify-content: center;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

input {
  background-color: var(--color-inputfield);
  color: var(--color-primary);
  border: none;
  outline: none;
  height: calc(var(--unit) * 2);
  width: 100%;
  box-sizing: border-box;

}

input[type=text],
input[type=url],
input[type=tel],
input[type=number],
input[type=color],
input[type=email],
input[type="password"] {
  padding-inline: var(--unit);
}

input[type=text]:focus,
input[type=url]:focus,
input[type=tel]:focus,
input[type=number]:focus,
input[type=color]:focus,
input[type=email]:focus,
input[type="password"]:focus {
    outline: 1px solid var(--color-primary);
}

ul li {
    margin-bottom: var(--unit); /* Adjust this value to increase or decrease spacing */
}

.textlink {
  color: var(--color-accent-primary);
  cursor: pointer;
  transition: color 0.3s ease;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none !important;
}

.textlink.small {
  font-size: calc(var(--unit) * 0.625);
}

.textlink:hover {
  color: var(--color-accent-secondary);
}

.textlink.subtle {
  color: var(--color-lightgrey);
}

.textlink.subtle:hover {
  color: var(--color-accent-primary);
}

.textlink.subtle.small {
  font-size: calc(var(--unit) * 0.625);
}

.text.small {
  font-size: calc(var(--unit) * 0.625);
}

button {
  background-color: var(--color-accent-primary);
  border: none;
  font-weight: bold;
  cursor: pointer;
  transition: background-color var(--animation-speed-standard) ease;
  color: black;
  transition: background-color 0.3s;
  height: calc(var(--unit) * 2.5);
  padding: var(--unit);
  box-sizing: border-box;
}

button:hover {
  background-color: var(--color-accent-secondary);
}

.buttonrow {
  display: flex;
  flex-direction: row;
  gap: var(--unit);
  width: 100%;
  justify-content: center;
}

input[type="checkbox"] {
  background-color: var(--color-inputfield);
  margin: 0;
  width: calc(var(--unit) * 2.5); 
  height: calc(var(--unit) * 1.25);
  border-radius: var(--unit);
  display: inline-block;
  position: relative;
  cursor: pointer;
  transition: background-color 120ms ease-in-out, background-color 240ms ease-in-out;
}

input[type="checkbox"]:hover {
    background-color: var(--color-lightgrey);
}

input[type="checkbox"]::before {
  content: "";
  display: block;
  width: calc(var(--unit) * 1.25);
  height: calc(var(--unit) * 1.25);
  background-color: var(--color-primary);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  transition: left 120ms ease-in-out;
}

input[type="checkbox"]:checked {
  background-color: var(--color-accent-primary);
}

input[type="checkbox"]:checked:hover {
  background-color: var(--color-accent-secondary);
}

input[type="checkbox"]:checked::before {
  left: calc(100% - var(--unit) * 1.25);
}

.login-page {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100dvh;
    padding: var(--unit);
    box-sizing: border-box;
}

.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
}

.vstack-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.custom-checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: calc(var(--unit) * 0.75);
  cursor: pointer;
  font-size: var(--unit);
  color: var(--color-primary);
  margin-top: calc(var(--unit) * 1.5);
}

.custom-checkbox.spread {
  width: 100%;
  justify-content: space-between;
  margin-top: 0;
}

.unit-link {
  padding-top: calc(var(--unit) * .25);
  font-size: calc(var(--unit) * .75);
}

.password-link {
  color: var(--color-lightgrey);
  text-decoration: none;
  transition: color 0.3s ease;
  font-size: calc(var(--unit) * .75);
  margin-top: calc(var(--unit) * 2);
  margin-bottom: calc(var(--unit) * 2);
  cursor: pointer;
}

.password-link:hover {
  color: var(--color-accent-primary);
}

.leave-link {
  margin-top: calc(var(--unit) * 1.5);
  color: var(--color-accent-primary);
  text-decoration: none;
  transition: color 0.3s ease;
  font-size: calc(var(--unit) * .75);
}

.logo {

  max-width: var(--logo-width);
  min-width: var(--logo-width);
}

.scrollable-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  padding: var(--unit);
  overflow-y: auto;
  box-sizing: border-box;
}


.scrollable-page h1 {
  margin-top: 0;
  margin-bottom: 0;
}

.square {
  z-index: -1;
  background-color: var(--color-inputfield);
  width: 100vh;
  height: 100vh;
  bottom: -50%;
  rotate: 45deg;
  opacity: 0.25;
  overflow: hidden;
}

.dashboard {
  height: 100dvh;
  width: 100dvw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.welcome-content {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: var(--unit);
  justify-content: center;
}

.welcome-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--unit);
  padding: var(--unit);
  border-radius: var(--unit);
  background-color: var(--color-level-window);
  box-sizing: border-box;
  backdrop-filter: blur(2px);
  transition: background-color 1s ease;
  max-width: calc(var(--logo-width));
  overflow: hidden;
}


.welcome-container h2 {
  font-size: calc(var(--unit) * 0.75);
  margin: 0;
}

.welcome-logout {
  top: var(--unit);
  left: var(--unit);
  font-size: calc(var(--unit) * 0.75);
  padding: calc(var(--unit) * 0.75);
  height: calc(var(--unit) * 1.5);
}

.logout-button-container {
  display: flex;
  justify-content: center;
  margin-top: calc(var(--unit) * 3);
}

.alert-message {
  font-style: italic;
  font-size: calc(var(--unit) * 0.75);

}

.alert-message.important {
  color: red; /* Important alert/error message color */
}

.alert-message.success {
  color: green;
}

.profile-picture-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.profile-picture {
  width: calc(var(--unit) * 5);
  height: calc(var(--unit) * 5);
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid var(--color-accent-primary);
  cursor: pointer;
}

.profile-picture:hover {
  border: 2px solid var(--color-accent-secondary);
}

.adjust-picture {
  display: flex;
  flex-direction: column;
  gap: var(--unit);
  justify-content: center;
}

.adjust-picture canvas {
  border: 2px solid var(--color-accent-primary);
  border-radius: 50%;
  cursor: grab;
}

.adjust-picture canvas:active {
  cursor: grabbing;
}

/* Container styling for range control */
input[type="range"] {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Range slider styling */
input[type="range"] {
    -webkit-appearance: none; /* Override default appearance */
    appearance: none; /* Override default appearance */
    width: 100%; /* Adjust the width as needed */
    height: calc(var(--unit) * 2); /* Adjust the height as needed */
    background: var(--color-lightgrey); /* Default track color */
    outline: none; /* Remove outline */
    border-radius: var(--unit); /* Rounded corners for the thumb */
    position: relative; /* For pseudo-elements */
    padding: 0;
    margin-right: var(--unit);
}

/* Thumb styling */
input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default appearance */
    appearance: none; /* Override default appearance */
    width: calc(var(--unit) * 2); /* Adjust the width as needed */
    height: calc(var(--unit) * 2); /* Adjust the height as needed */
    background: var(--color-primary); /* Thumb color */
    border-radius: 50%; /* Rounded corners for the thumb */
    cursor: pointer; /* Change cursor to pointer */
    position: relative;
    z-index: 2; /* Ensure thumb is on top */
    transition: background-color 0.3s ease;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

input[type="range"]::-moz-range-thumb {
    width: var(--unit); /* Adjust the width as needed */
    height: var(--unit); /* Adjust the height as needed */
    background: var(--color-primary); /* Thumb color */
    border-radius: 50%; /* Rounded corners for the thumb */
    cursor: pointer; /* Change cursor to pointer */
    position: relative;
    z-index: 2; /* Ensure thumb is on top */
    transition: background-color 0.3s ease;
}

input[type="range"]::-ms-thumb {
    width: var(--unit); /* Adjust the width as needed */
    height: var(--unit); /* Adjust the height as needed */
    background: var(--color-primary); /* Thumb color */
    border-radius: 50%; /* Rounded corners for the thumb */
    cursor: pointer; /* Change cursor to pointer */
    position: relative;
    z-index: 2; /* Ensure thumb is on top */
    margin-top: calc(var(--vol-height) / -2); /* Center the thumb vertically */
    transition: background-color 0.3s ease;
}

/* input[type="range"]::-webkit-slider-thumb:hover {
    background-color: var(--color-accent-secondary);
}

input[type="range"]::-moz-range-thumb:hover {
    background-color: var(--color-accent-secondary);
}

input[type="range"]::-ms-thumb:hover {
    background-color: var(--color-accent-secondary);
}

input[type="range"]:hover::-webkit-slider-thumb {
    background-color: var(--color-accent-secondary);
}

input[type="range"]:hover::-moz-range-thumb {
    background-color: var(--color-accent-secondary);
}

input[type="range"]:hover::-ms-thumb {
    background-color: var(--color-accent-secondary);
} */

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px 0;
  z-index: 1000;
}

.dropdown-item {
  padding: 5px 10px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}

.login-confirmationsent-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--unit);
}

.passwordrow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: calc(var(--unit) * 0.5);
}

.password-visible-toggle {
  display: flex;
  justify-content: center;
  cursor: pointer;
  color: var(--color-primary);
  width: calc(var(--unit) * 2);
  transition: color 0.3s ease;
}

.password-visible-toggle:hover {
  color: var(--color-accent-primary);
}

.login-error {
  color: red;
  max-width: var(--logo-width);
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100dvh;
  width: 100dvw;
  font-size: calc(var(--unit) * 2);
}

.disclaimer-links {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: var(--unit);
  font-size: calc(var(--unit) * 0.625);
  color: var(--color-lightgrey);
}

.column-tight {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: calc(var(--unit) * 0.5);
}

/* Modal.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  font-size: calc(var(--unit) * 0.75);
}

.modal-window {
  background: var(--color-window);
  padding: var(--unit);
  margin: var(--unit);
  border-radius: calc(var(--unit) * 0.5);
  width: 100%;
  max-width: 800px;
  max-height: calc(100dvh - (var(--unit) * 4));
  display: flex;
  flex-direction: column;
  box-shadow: 0px calc(var(--unit) * 0.5) calc(var(--unit) * 0.5) black;

}

.modal-alert {
  background: var(--color-window);
  padding: var(--unit);
  margin: var(--unit);
  border-radius: calc(var(--unit) * 0.5);
  max-width: var(--logo-width);
  max-height: calc(100dvh - (var(--unit) * 4));
  display: flex;
  flex-direction: column;
  box-shadow: 0px calc(var(--unit) * 0.5) calc(var(--unit) * 0.5) black;
}

.modal-alert-content {
  display: flex;
  flex-direction: column;
  gap: calc(var(--unit) * 1.5);
}

.modal-alert-header {
  display: flex;
  font-weight: bold;
  justify-content: space-between;
  align-items: center;
  padding-left: calc(var(--unit) * 0.5);
  margin-bottom: calc(var(--unit) * 0.25);
}

.modal-banner {
  background: var(--color-window);
  position: fixed;
  padding: var(--unit);
  margin: var(--unit);
  border-radius: calc(var(--unit) * 0.5);
  justify-self: center;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px calc(var(--unit) * 0.5) calc(var(--unit) * 0.5) rgba(0, 0, 0, 0.25);
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
}

.modal-banner-content {
  display: flex;
  flex-direction: column;
  gap: calc(var(--unit) * 1.5);
}


.modal-banner-header {
  display: flex;
  font-weight: bold;
  justify-content: space-between;
  align-items: center;
  padding-left: calc(var(--unit) * 0.5);
  margin-bottom: calc(var(--unit) * 0.25);

}

.modal-window-header {
  display: flex;
  font-weight: bold;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding-bottom: var(--unit);
  margin-bottom: var(--unit);
  padding-left: calc(var(--unit) * 0.5);
}

.modal-content {
  text-align: left;
  flex: 1;
  overflow-y: auto;
  padding: calc(var(--unit) * 0.5);
  display: flex;
  flex-direction: column;
  gap: calc(var(--unit) * 1.5);
}

.modal-close-button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--unit);
  cursor: pointer;
  border-radius: calc(var(--unit) * 0.25);
  min-width: calc(var(--unit) * 1.5);
  min-height: calc(var(--unit) * 1.5);
  color: var(--color-close-button);
}

.modal-close-button:hover {
  background-color: black;
  cursor: pointer;
}

.smalltext {
  font-size: calc(var(--unit) * 0.75);
}